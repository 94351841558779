import React, { useEffect, useState } from "react";
import Button from "../elements/Button";
import Drawer from "./Drawer";
import Comments from "./Comments";
import { Icon } from "@iconify/react";
import Tip from "./Tip";
import { FeatureFlag } from "./FeatureFlag";
import Badge from "./Badge";
import MenuTag from "./MenuTag";
import QuestionToolTip from "./QuestionToolTip";
import { utils } from "../modules/_utils";

const CommentButton = (
  {
    profile,
    from,
    solicitation,
    project,
    response,
    handlePost,
    comments,
    commentText,
    setCommentText,
    commentType,
    color,
    loading,
    handleLike,
    size,
  },
  props
) => {
  const [open, setOpen] = useState(false);
  //   const [loading, setLoading] = useState(false);

  //   useEffect(() => {
  //     setLoading(props.loading);
  //   }, [props.loading]);

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(isOpen);
  };

  return (
    <>
      <div className="relative ">
        {/* <FeatureFlag featureName="Comment on Bid Request" type="flag"> */}
        <Button
          size={size ? size : "xs"}
          color={color}
          handleClick={toggleDrawer(true)}
        >
          <div className="relative flex items-center">
            <div className="relative">
              {comments && comments.length > 0 && (
                <div class="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-semibold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-0 ">
                  {/* <span class="inline-flex items-center justify-center w-4 h-4 ml-2 text-xs font-semibold text-red-800 bg-red-200 rounded-full"> */}
                  {comments.length}
                </div>
              )}
              <Icon
                className="mr-2 h-5 w-5  group-hover:bg-gray-50"
                //save icon
                icon="mdi:comment-outline"
              />
            </div>{" "}
            Comments
            {/* <MenuTag status={"BETA"} /> */}
          </div>{" "}
        </Button>
        {/* </FeatureFlag> */}
      </div>
      <Drawer
        anchor="bottom"
        title="Discussion"
        show={open}
        setShow={toggleDrawer(false)}
      >
        {" "}
        <div
          className="h-full    col-span-full px-0 pt-3 pb-20 sm:px-0 
              overflow-y-auto scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100  
              scrollbar-thumb-rounded-full scrollbar-track-rounded-full
              "
        >
          <div className="flex px-6 font-semibold">
            View and add comments below.
          </div>

          <div className="block px-6">
            <Tip>
              {from == "vendor"
                ? "Your comment will be visible to the requestor and all vendors but we will keep your identity anonymous to other vendors."
                : "Your comment and company name will be visible to all vendors."}
            </Tip>
          </div>
          {from == "vendor" && (
            <div className="flex px-6">
              Leave a comment
              {/* <MenuTag status={"FREE PREVIEW"} /> */}
              {/* <QuestionToolTip
                color={"brand"}
                field={"Free Preview"}
                text={utils.getTooltipText("Free Preview")}
              /> */}
            </div>
          )}

          <Comments
            profile={profile}
            from={from}
            commentType={commentType}
            solicitation={solicitation}
            project={project}
            response={response}
            handlePost={handlePost}
            comments={comments}
            commentText={commentText}
            setCommentText={setCommentText}
            loading={loading}
            handleLike={(e, id) => handleLike(e, id)}
          />
        </div>
      </Drawer>
    </>
  );
};

export default CommentButton;
